.modal-content {
    background: linear-gradient(to right bottom, #1b1d22, #2A2E35);
    color: white;
}

.selection-modal {
    position: absolute;
    right: 0;
    top: .9rem;
    /* top: 160%;
        transform: translateY(-160%); */
}

/* ButtonAllColor */
.ColorButton {
    background-image: linear-gradient(to right, #0b545d, #057481) !important;
}

.ColorButtonTwo {
    background: rgb(2, 0, 36) !important;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 41%, rgba(23, 150, 176, 1) 100%) !important;
}

.skip-btn {
    font-size: 14px;
    border-bottom: 1px solid #DC3545;
    margin-left: 3px;
    cursor: pointer;
    color: #DC3545;
}

.skip-btn:hover {
    color: white;
    border-color: white
}

.money-img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.money-button1 {
    color: goldenrod !important;
    padding: 10px 15px !important;
    background-color: black !important;
    margin-right: 20px !important;
}

.money-button2 {
    color: black !important;
    padding: 8px 15px !important;
    background-color: #f7f7f7 !important;
}

/* .money-modal .swal-footer{
    text-align: center;
} */
.money-modal .swal-title {
    font-size: 21px !important;
    font-weight: 400;
}

/* .money-modal .swal-footer button{
    width: 220px;
} */

@media screen and (max-width:1000px) {
    .selection-login .connectMenu {
        width: 60%;
    }
}

@media screen and (max-width:567px) {

    .money-button1,
    .money-button2 {
        /* width: 255px !important; */
        padding: 10px 0px !important;
        margin-right: 0px !important;
        margin-bottom: 10px !important;
    }

    .money-button2 {
        padding: 8px 0px !important;
        margin-bottom: 20px !important;
    }

    .money-img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .money-title {
        padding: 16px 15px !important;
        font-size: 20px !important
    }
}
.making_white-ch p {
    color: #fff !important;
}

.making_white-ch p span strong {
    color: #00e4ff !important;
    font-weight: 600;
}

.making_white-ch p span {
    color: white !important;

}
.fontArial {
  font-family: Arial, Helvetica, sans-serif !important;
  font-style: normal;
  /* font-size: 1px; */
}

.category-wrapper {
}

.savethehostage .table-bordered,
.savethehostage .table-bordered td {
  border-color: white !important;
}

.success {
  color: greenyellow;
}

.pagination-btn {
  margin-top: 16px;
}

.pagination-btn button {
  color: white;
  background-color: darkslategrey;
  margin-top: 8px;
}

.pagination-btn button.Mui-selected {
  color: white;
  background-color: red !important;
}

.pagination-btn .MuiPaginationItem-sizeMedium {
  color: white !important;
}

.pagination-btn nav ul {
  justify-content: center;
}

.widthDetailsInput {
  width: 75%;
}

.diffrentBack1 {
  background-color: #413d42;
  width: 90%;
  padding: 12px;
}

.diffBack2 {
  background-color: #45002d;
  width: 90%;
  padding: 12px;
}
.details-page .landing-button {
  width: 75%;
}

.details-page .landing-button button {
  width: 100%;
  max-width: initial;
  padding: 10px 0;
}

.slider-nft {
  width: 50%;
  margin: 0 auto;
}

.nft-card {
  min-height: 90px;
}

.handleBarcode {
  position: relative;
  left: 27.1rem;
  top: -126px;
  justify-self: end;
  width: 20% !important;
  height: auto !important;
}

.img_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 2rem 0;
}

.video_div {
  width: 100%;
  height: 400px;
  margin-top: -124px;
}

.details_video {
  width: 100%;
  height: 100%;
}

.payTexts {
  margin-bottom: 0;
}

.paynowPaidButton {
  background-color: #0d6efd;
  color: white;
  width: 300px;
  padding: 6px 8px;
  border-radius: 6px;
  border: none;
}

.pricePayNow {
  color: #0d6efd;
}

.paynowBTNC {
  padding: 2px 6px;
  margin-left: 5px;
  border-radius: 10px;
  background: #0d6efd;
  margin-bottom: 1rem;
  border: none;
}

.paynowLaterPaidButton {
  background-color: transparent;
  border: 2px solid #0d6efd;
  color: #0d6efd;
  width: 300px;
  padding: 6px 8px;
  border-radius: 6px;
}

.companyDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonDiv {
  margin-bottom: -0.1rem;
  padding-bottom: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.nft-watermark {
  position: relative;
  opacity: 0.9;
  right: 3.3rem;
  top: 15px;
  justify-self: end;
  width: 10% !important;
  height: auto !important;
  visibility: hidden;
}

.nft-watermark3 {
  position: relative;
  opacity: 0.9;
  left: 0rem;
  bottom: 23.5rem;
  justify-self: end;
  width: 10% !important;
  height: auto !important;
}

.nft-watermark2 {
  position: relative;
  opacity: 0.9;
  left: 12.2rem;
  top: 6rem;
  justify-self: end;
  width: 14% !important;
  height: auto !important;
}

.fontExtand {
  font-size: 1rem;
}

.spaceIssue p {
  /* padding-bottom: -130px; */
  margin-bottom: 0px;
}

.handlePosition {
  position: relative;
  top: -7px;
  left: -25px;
}

.priceDropdown {
  display: flex;
  align-items: center;
}

.viewall {
  text-align: center;
  text-decoration: none;
  padding-top: 100px;
  color: #0d6efd;
  font-weight: normal;
}

.certificateCelebrity {
  margin-bottom: 0rem;
  height: 83.5%;
}

.paynow_disable_button {
  width: 75%;
  /* border-radius: 10px; */
  cursor: auto;
}

.paynow_enable_button {
  width: 75%;
  /* border-radius: 10px; */
  cursor: pointer;
}

.free-nft-code-input::placeholder {
  color: white !important;
}

@media only screen and (max-width: 1000px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: -100px;
  }
}

@media only screen and (max-width: 979px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: -72px;
  }
}

@media only screen and (max-width: 767px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: -110px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .video_div {
    width: 100%;
    height: 400px;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .pagination-btn nav {
    height: auto !important;
  }
}

@media only screen and (max-width: 600px) {
  .paynow_disable_button {
    width: 75%;
    cursor: auto;
  }

  .diffrentBack1 {
    background-color: #413d42;
    width: 100%;
    padding: 12px;
  }

  .diffBack2 {
    background-color: #45002d;
    width: 100%;
    padding: 12px;
  }
  .widthDetailsInput {
    width: 100%;
  }

  .paynow_enable_button {
    width: 75%;
    cursor: pointer;
  }

  .video_div {
    width: 100%;
    height: 400px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 376px) {
  .paynow_disable_button {
    width: 75%;
    cursor: auto;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .paynow_enable_button {
    width: 75%;
    cursor: pointer;
  }

  .handleBarcode {
    position: relative;
    /* left: 3rem;
        top: 8.7rem; */
    left: 2rem;
    top: 8rem;
    justify-self: end;
    width: 15% !important;
  }

  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: -3.1rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 15.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: -0.1rem;
    bottom: 11.5rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .marginPhone {
    margin-top: 1rem;
  }
}

/* @media only screen and (min-width:321px) and (max-width: 335px) {
    .handleBarcode {
        position: relative;
        left: 4.6rem;
        top: 107px;
        justify-self: end;
        width: 20% !important;
        height: auto !important;
    }

    .nft-watermark {
        position: relative;
        right: -3.2rem;
        top: 10px;
        justify-self: end;
        width: 10% !important;
        height: auto !important;
    }

} */

@media only screen and (min-width: 377px) and (max-width: 425px) {
  .handleBarcode {
    position: relative;
    /* left: 3rem;
        top: 8.7rem; */
    left: 1.5rem;
    top: 9rem;
    justify-self: end;
    width: 15% !important;
    height: auto !important;
  }

  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .marginPhone {
    margin-top: 1rem;
  }

  .nft-watermark {
    position: relative;
    right: -2.9rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
    visibility: hidden;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 16rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: -0.1rem;
    bottom: 12.5rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }
}

/* @media only screen and (min-width:376px) and (max-width: 425px) {
    .handleBarcode {
        position: relative;
        left: 2.5rem;
        top: 132px;
        justify-self: end;
        width: 20% !important;
        height: auto !important;
    }

    .nft-watermark {
        position: relative;

        right: -1rem;
        top: 10px;
        justify-self: end;
        width: 10% !important;
        height: auto !important;
    }
} */

@media only screen and (min-width: 426px) and (max-width: 500px) {
  .handleBarcode {
    position: relative;
    /* left: 3rem;
        top: 8.7rem; */
    left: -0.1rem;
    top: 10.5rem;
    justify-self: end;
    width: 15% !important;
    height: auto !important;
  }

  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: -2.5rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .marginPhone {
    margin-top: 1rem;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: -0.1rem;
    bottom: 14rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 699px) {
  .handleBarcode {
    position: relative;
    /* left: 3rem;
        top: 8.7rem; */
    left: -1rem;
    top: 11.2rem;
    justify-self: end;
    width: 15% !important;
    height: auto !important;
  }

  .widthDetailsInput {
    width: 100%;
  }

  .certificateCelebrity {
    height: 100%;
    margin-bottom: 0;
  }

  .marginPhone {
    margin-top: 1rem;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: -1.5rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: -0.1rem;
    bottom: 16.5rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .handleBarcode {
    position: relative;
    left: 16rem;
    top: -6rem;
    width: 16.6% !important;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    left: 26.5rem;
    top: -18rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: -0.1rem;
    bottom: 24.2rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }
}

/* @media only screen and (min-width:768px) and (max-width: 979px) {
    .handleBarcode {
        position: relative;
        left: 1rem;
        top: 10.5rem;

    }

    .nft-watermark {
        position: relative;

        right: -1rem;
        top: 10px;
        justify-self: end;
        width: 10% !important;
        height: auto !important;
    }
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .handleBarcode {
    position: relative;
    left: 12rem;
    top: -4.5rem;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: 2.1rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: 2rem;
    bottom: 15rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1184px) {
  .handleBarcode {
    position: relative;
    left: 19rem;
    top: -6.5rem;
  }

  .nft-watermark {
    position: relative;
    visibility: hidden;

    right: 2rem;
    top: 10px;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }

  .nft-watermark2 {
    position: relative;
    opacity: 0.9;
    left: 17.5rem;
    top: 6.5rem;
    justify-self: end;
    width: 11% !important;
    height: auto !important;
  }

  .nft-watermark3 {
    position: relative;
    opacity: 0.9;
    right: 2.7rem;
    bottom: 20rem;
    justify-self: end;
    width: 10% !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 1185px) and (max-width: 1399px) {
  .handleBarcode {
    position: relative;
    left: 23.2rem;
    top: -108px;
    justify-self: end;
    width: 20% !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 567px) {
  .slider-nft {
    width: 100%;
  }

  .nft-card {
    min-height: 70px;
  }

  .details-page .landing-button {
    width: 100%;
  }
}

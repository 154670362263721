.mintDetails {
    height: 100vh;
    background-color: #1A1A25;
    padding-top: 7rem;
}

.profBTN {
    display: block;
}

.profBTN2 {
    display: none;
}

@media only screen and (max-width: 767px) {
    .profBTN {
        display: none;
    }
    .profBTN2 {
        display: block;
    }
}
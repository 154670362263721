.background-video {
  background-image: url("../../../../public/backbanner.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 620px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  z-index: 1;
  /* position: relative; */
  /* background-position: bottom; */
  /* background-size: cover; */
}
.closing {
  background: linear-gradient(90deg, #ec0321, #d60257, #b303a6, #a502ce);
}
.background-video::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: -1;
}

#card-overlay {
  object-fit: cover;
  width: 100%;
  height: 620px !important;
  z-index: 1;
  overflow: hidden !important;
}

.flexbanner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.bannerBg {
  /* background-image: url("https://i.ibb.co/z6W1CJf/20-dc5e2b870a07316225ec.jpg"); 
  background-position: center;  
  background-repeat: no-repeat;  
  background-size: cover;   */
  /* background: #1A1A25; */
}

/* ----------------HowItWorks-------------- */
.backgroundSection {
  background-color: #130a1d !important;
  /* padding-top: 50px; */
  /* padding-bottom: 0px !important; */
}

.specific {
  margin-top: 36px;
}

.boxesNft {
  height: 236px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .home-txt-pd {
  padding-top: -40px !important
} */

.reg_button-landHome {
  display: flex;
  justify-content: flex-start;
}

.reg_button-landXp {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}

.banner-button {
  background-image: linear-gradient(90deg, #ad1ff9 0, #550570 51%, #8e099bcc);
  background-size: 200% auto;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  margin: 10px 0px 10px 0px;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
}

.banner-button:hover {
  background-position: 100%;
  color: #fff;
  text-decoration: none;
}

.banner-buttonMeta {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
  background: rgb(169, 3, 41);
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(169, 3, 41, 1) 0%,
    rgba(143, 2, 34, 1) 44%,
    rgba(109, 0, 25, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(169, 3, 41, 1) 0%,
    rgba(143, 2, 34, 1) 44%,
    rgba(109, 0, 25, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(169, 3, 41, 1) 0%,
    rgba(143, 2, 34, 1) 44%,
    rgba(109, 0, 25, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a90329', endColorstr='#6d0019', GradientType=0);
  /* IE6-9 */

  /* background-image: linear-gradient(90deg, #ad1ff9 0, #550570 51%, #8e099bcc); */
  background-size: 200% auto;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  margin: 10px 0px 10px 0px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
}

.banner-buttonMeta:hover {
  background: rgb(169, 3, 41);
  background: linear-gradient(
    to bottom,
    rgb(153, 0, 36) 0%,
    rgbargb(121, 0, 28),
    rgb(71, 0, 17) 100%
  );
  background-position: 100%;
  color: #fff;
  text-decoration: none;
}

.handleStandTextWidth {
  width: 255px;
  margin-left: 0px;
}

.forLetterSpacing {
  letter-spacing: 12px;
}

/* @media only screen and (min-width: 1200px) {
  .forLetterSpacing {
    letter-spacing: 10px;
  }
} */

@media only screen and (min-width: 980px) and (max-width: 1030px) {
  .forLetterSpacing {
    letter-spacing: 12px;
  }
}

@media screen and (max-width: 768px) {
  .reg_button-landHome {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .forLetterSpacing {
    letter-spacing: 5px;
  }

  .reg_button-landXp {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .handleStandTextWidth {
    width: 280px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .banner-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner-buttonMeta {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .background-video {
    height: 1030px;
  }

  .flexbanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  #card-overlay {
    height: 1030px !important;
  }
}

@media screen and (max-width: 400px) {
  .forLetterSpacing {
    letter-spacing: 2px !important;
  }
}

@media screen and (max-width: 300px) {
  .forLetterSpacing {
    letter-spacing: 2px !important;
  }
}

.admin_tabs_menu_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0;
    text-align: center;
    width: 100%;
}

.tabs_tab_btn {
    background: #4d5f71;
    max-width: 220px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    color: #f2f6fe;
    margin: 0 2%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    border: 2px solid #f2f6fe;
    border-radius: 10px;
}

.tabs_tab_btn:hover {
    background-color: #8a84d8;
    transition: 0.2s ease;
}

.active_tabs_tab_btn {
    background: #8a84d8;
    color: #f2f6fe;
    max-width: 220px;
    width: 100%;
    border: 2px solid #f2f6fe;
    border-radius: 10px;
}

.single_tab_content {
    display: none;
}

.active_single_tab_content {
    display: block;
}
.layer_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0.7;
  background: rgba(0, 0, 0, 0.932);
  display: grid;
}

.relatedNews {
  font-size: 18px;
  text-transform: uppercase;
  color: #444444;
  letter-spacing: 0.5px;
  margin: 0 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Source Serif Pro", serif !important;
  text-align: left;
}

.relatedNews_name {
  font-size: 14px;
  line-height: 25px;
  text-decoration: none;
  color: #222 !important;
}

.relatedNews_date {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-decoration: none;
  color: #222 !important;
}

.padding_left_25 {
  padding-left: 40px;
}

.j_c_f_e {
  justify-content: flex-end !important;
}

.byAdmin {
  color: #444;
  font-size: 14px !important;
  font-weight: 400;
  transition: all 0.24s ease-in-out;
}

.footerLink :hover .byAdmin {
  color: #32b9c5 !important;
}

.personIcon {
  font-size: 1.2rem !important;
  margin-right: 5px;
}

.news-title {
  font-size: 24px !important;
  font-weight: 600;
  color: #32b9c5 !important;
  /* font-family: "Source Serif Pro", serif !important; */
  font-family: Arial, Helvetica, sans-serif !important;
  text-decoration: none;
}

.news__text {
  font-size: 24px !important;
  font-family: "Source Serif Pro", serif !important;
}

.a_i_c {
  align-items: center;
}

.row {
  padding: 0 !important;
}


.news-description figure:first-child {
  display: none;
}


@media only screen and (max-width: 769px) {
  .news_banner {
    height: 25vh;
  }
}
body {
  background-color: #130a1d !important;
}

.handleAlignmentBanner {
  text-align: left;
}

.bg-gray {
  background-color: gray !important;
}

.check-login {
  background: transparent;
  border: none;
  color: white;
  width: 100%;
}

/* btn growing  start*/

.btn-wallet-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3b2e89;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  width: auto;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0;
  /* animation: flash 1s infinite; */

  -webkit-animation: glowing_btn_metamask 1500ms infinite;
  -moz-animation: glowing_btn_metamask 1500ms infinite;
  -o-animation: glowing_btn_metamask 1500ms infinite;
  animation: glowing_btn_metamask 1500ms infinite;
}

@keyframes glowing_btn_metamask {
  0% {
    background-color: #3b2e89;
    -webkit-box-shadow: 0 0 3px #3b2e89;
  }

  50% {
    background-color: #3b2e89;
    -webkit-box-shadow: 0 0 20px #3b2e89;
  }

  100% {
    background-color: #3b2e89;
    -webkit-box-shadow: 0 0 3px #3b2e89;
  }
}

.animated-button {
  background: #3b2e89;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(orange)
  );
  background: linear-gradient(to left, rgba(8, 20, 43, 0), orange);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(orange)
  );
  background: linear-gradient(to top, rgba(8, 20, 43, 0), orange);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 20, 43, 0)),
    to(orange)
  );
  background: linear-gradient(to right, rgba(8, 20, 43, 0), orange);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: -31px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(8, 20, 43, 0)),
    to(orange)
  );
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), orange);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

/* btn growing end  */

.imgWalletBtn {
  width: 30px !important;
  margin: 0 auto;
  margin-top: -5px;
}

.mySwiper {
  width: 100%;
}

.sliderWidth {
  width: 668px;
}

.paddingLeftAlign {
  padding-left: 5px;
}

.onHideLgSlider {
  display: grid;
}

.onHideSmSlider {
  display: none;
}

.play-gifs {
  display: flex;
  flex-direction: column;
}

.onHideSm {
  display: grid !important;
}

.onHideLg {
  display: none !important;
}

.centralInMobile {
  text-align: left;
}

.swal-footer {
  text-align: center !important;
}

.swal-button--cancel {
  width: 100px;
}

.swal-button--confirm {
  width: 100px;
}

.minteddetaLspAge_ht {
  min-height: "80vh";
}

.handleVideoWidth {
  width: 400px;
  height: 400px;
}

.btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

/* modal css  */
.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 15px;
  overflow-wrap: break-word;
  color: white;
}

.about_details {
  color: white;
}

.link_hash {
  color: white;
}

.iconClose {
  position: absolute;
  top: 2px;
  right: 5px;
  width: 40px;
  height: 40px;
  background: none !important;
  border: none !important;
  font-size: 25px !important;
}

.home-padding {
  padding-top: 0px !important;
}

/* Landing page carosule slider */
.container-slider {
  max-width: 700px;
  height: 464px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.handleLogoDiv {
  position: relative;
  /* top: -715px; */
  z-index: 10;
  /* margin-bottom: -660px !important */
}

.background-image {
  background-position: center !important;
  background-image: url(../public/backbanner.jpg) !important;
  background-size: cover !important;
  height: 815px;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: overlay !important;
}

@media only screen and (min-width: 901px) and (max-width: 1400px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 800px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .handleLogoDive {
    top: -715px;
    margin-bottom: -680px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1380px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .handleLogoDive {
    top: -1300px;
    margin-bottom: -1250px !important;
  }
}

@media only screen and (min-width: 721px) and (max-width: 799px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1400px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .handleLogoDive {
    top: -1300px;
    margin-bottom: -1250px !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 720px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1370px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 80px;
  }

  .handleLogoDive {
    top: -1260px;
    margin-bottom: -1250px !important;
  }
}

@media only screen and (min-width: 486px) and (max-width: 500px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1360px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 52px;
  }

  .handleLogoDive {
    top: -1260px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 471px) and (max-width: 485px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1360px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 45px;
  }

  .handleLogoDive {
    top: -1260px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 451px) and (max-width: 470px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1360px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 35px;
  }

  .handleLogoDive {
    top: -1260px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 436px) and (max-width: 450px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1360px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 25px;
  }

  .handleLogoDive {
    top: -1260px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 426px) and (max-width: 435px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1360px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 20px;
  }

  .handleLogoDive {
    top: -1260px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 420px) and (max-width: 425px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1150px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 10px;
  }

  .handleLogoDive {
    top: -1040px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 381px) and (max-width: 419px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1150px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .paddingLeftAlign {
    padding-left: 5px;
  }

  .handleLogoDive {
    top: -1040px;
    margin-bottom: -1000px !important;
  }
}

@media only screen and (min-width: 366px) and (max-width: 380px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1090px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .handleLogoDive {
    top: -960px;
    margin-bottom: -1000px !important;
  }

  .paddingLeftAlign {
    padding-left: 5px;
  }

  /* .handleFestModalButton {
    margin-top: -45px !important;
  } */
}

@media only screen and (min-width: 301px) and (max-width: 365px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1130px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .handleLogoDive {
    top: -1025px;
    margin-bottom: -1000px !important;
  }

  .paddingLeftAlign {
    padding-left: 5px;
  }

  .handleFestModalButton {
    margin-top: -45px !important;
  }
}

@media only screen and (max-width: 300px) {
  .background-image {
    background-position: center !important;
    background-image: url(../public/backbanner.jpg) !important;
    background-size: cover !important;
    height: 1100px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    background-blend-mode: overlay !important;
  }

  .handleLogoDive {
    top: -1000px;
    margin-bottom: -1000px !important;
  }

  .paddingLeftAlign {
    padding-left: 5px;
  }
}

.handleHyper {
  position: relative;
  top: -1.5px;
}

/* fest modal  */

.handleFestModalButton {
  margin-top: -55px;
}

/* grihund race video */
.grugHundRace {
  min-width: 0% !important;
  max-width: 50% !important;
}

.grugHundRaceText {
  min-width: 0% !important;
  max-width: 50% !important;
  padding-left: 0px !important;
}

.grugHundRacev2 {
  min-width: 0% !important;
  max-width: 50% !important;
  min-height: 40px !important;
  max-height: 500px !important;
}

.grugHundRacev2Text {
  min-width: 0% !important;
  max-width: 50% !important;
  min-height: 40px !important;
  max-height: 500px !important;
  padding-left: 13px;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.active-anim {
  opacity: 1;
}

.handleAboutFlex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;
}

#forTrans {
  transition: block none 0.24s;
}

/* .handleImgforRespons {

  height: 450px !important;
  
} */

#more {
  display: none;
}

#aboutMore {
  display: none;
}

.carousel-slider {
  width: 93%;
  height: 464px;
  margin: 0 auto;
  padding-top: 0;
}

.howItWorks_section-p {
  padding-top: 50px;
}

.banner-button2 {
  /* background-color: rgb(123, 123, 148) !important; */
  margin: 10px;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  border: none;
}

.banner-button:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.font14 {
  font-size: 14px;
}

#headline {
  color: red !important;
}

.otp-btn {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.sbmt-btn {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.home-txt {
  font-size: 20px;
}

.NFtsection {
  margin-bottom: 0px;
}

.wait {
  color: #ff343f;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.loaders {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #db3450;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.css-tj5bde-Svg {
  color: #000 !important;
}

.css-8mmkcg {
  color: #000 !important;
}

.interest-banner-button2 {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #db1528 51%,
    #ff512f 100%
  );
  margin: 10px;
  padding: 5px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  border: none;
}

.interest-banner-button2:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

/* .home-txt-pd {
  padding-top: -50px !important;
 
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal_class {
  background-color: #0c2347;
}

.swal-modal {
  background-color: #0c2347;
}

.modal_class_success .swal-icon::before {
  background-color: transparent;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.success {
  color: #ff343f;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.extraCare {
  margin-top: 15px;
}

.swal-text {
  text-align: center;
  color: #ff343f;
  font-weight: normal;
}

/* swal */
.modal_class_success {
  background-color: #2d313a;
}

.swal-text {
  color: #fff;
  text-align: center !important;
}

.modal_class_success .swal-icon::before {
  background-color: transparent;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.address {
  font-weight: normal;
  width: 80%;
  color: #8fb9d4;
}

.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 13px;
  overflow-wrap: break-word !important;
  color: white;
}

.swal-title {
  color: #ff343f;
}

.swal-text {
  color: #fff;
}

.forRespoMarginReduce {
  margin-left: 70px !important;
  margin-right: 100px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.slider_nft_text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.93rem;
  font-weight: 400;
}

/* Wallet CSS */
.titleWallet {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: end;
}

.alertWalletDiv {
  margin-top: -2.5rem;
}

.walletIcon {
  width: 40px;
}

.wallet-texts {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
}

.dialogDiv-coinbase {
  background-color: #060c13;
  color: white;
}

.coinbase-dialog {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.click-here {
  cursor: pointer;
}

.contents {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #a2a2a2;
  text-align: center;
  margin-bottom: 0rem;
}

.NftDetailsDiv {
  margin-top: 35px;
}

.contentDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.walletDiv span {
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 2rem;
  font-weight: bold;
}

.walletDiv {
  width: 100% !important;
  cursor: pointer;
  border: none;
  text-align: center;
  padding-top: 26px;
  color: #a2a2a2;
  font-family: Montserrat;
  font-weight: bold;
  background-color: #060c13 !important;
}

.walletModal_icon {
  margin-left: 0% !important;
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.imgWallet {
  width: 50px !important;
  margin: 0 auto;
}

.dialogWallet {
  background-color: #060c13;
  color: white;
}

.dialogDiv {
  border-radius: 20px;
}

.walletName {
  font-size: 1rem;
  color: #a2a2a2;
}

.walletFont {
  margin-right: 6px;
}

.contents1 {
  color: rgb(177, 114, 236);
  text-decoration: none;
}

.contents1:hover {
  color: #fff;
}

.walletdiv {
  display: flex;
  align-items: center;
  justify-content: end;
}

.connectMenu {
  border: none;
  background-color: rgb(237, 41, 41);
  color: white;
  padding-top: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.meal_details_type_title {
  /* margin-left: 23.5px !important; */
  margin-bottom: -28px !important;
  font-size: 22px !important;
  padding-left: 8px !important;
}

.paymentOptionsChoose {
  margin-top: 0px !important;
  margin-bottom: -25px !important;
}

.howItWorksFs {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.font-resize {
  font-size: 50px;
}

.font-resizeSm {
  font-size: 70px;
}

.form-element {
  display: table-cell;
  box-sizing: border-box;
}

.xs-hide {
  display: none;
}

.banner-text {
  margin-top: -20px;
}

span.navbar-toggler-icon {
  width: 23px;
}

/* ------------------ */
.d-item1 {
  padding: 8px;
}

.d-itemVideo {
  padding: 8px;
  padding-right: 0px !important;
  display: flex !important;
  justify-content: end;
}

.slick-prev:before {
  line-height: 0;
  font-size: 50px !important;
  color: transparent !important;
}

button.slick-arrow.slick-prev {
  z-index: 500;
}

.slick-next:before {
  line-height: 0;
  font-size: 50px !important;
  color: transparent !important;
}

button.slick-arrow.slick-next {
  margin-right: 30px;
  z-index: 500;
}

/* --------------------------------- */
.CertificatModalButton {
  border-radius: 5px !important;
}

.titelColor {
  color: #4e95ff !important;
}

.titelColor2 {
  color: #9e44a1 !important;
}

/* -------------Footer css---------------------- */
.footer-main {
  background-color: #130a1d;
}

.text-center.my-3.footer {
  position: relative;
}

.made-love {
  color: rgb(236, 81, 81);
  font-size: 18px;
  font-weight: 600;
}

p.text-light.mb-0 {
  align-items: center;
  display: flex;
}

.icon-part {
  text-decoration: none;
}

.icon-sm {
  height: 30px;
  width: 30px;
  background-color: rgb(66, 66, 66);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-sm .icons {
  color: #dc3545;
  text-decoration: none;
}

.pricing-image-home img {
  opacity: 0.9;
  width: 100%;
  border-radius: 7px;
}

.d-flex.justify-content-center.gap-3.flex-row {
  display: flex;
  justify-content: center;
}

.headerButtonW {
  /* position: relative; */
  /* left: 20px; */
}

.section-intro {
  padding-top: -90px !important;
}

@media only screen and (max-width: 1030px) {
  .d-itemVideo {
    display: flex;
    justify-content: end;
  }
}

@media only screen and (max-width: 980px) {
  .meal_details_type_title {
    margin-left: 12px !important;
    margin-bottom: -28px !important;
    font-size: 18px !important;
  }

  /* .search-bar {
    width: 50% !important;
  } */
}

.souvenirNFT_Box {
  /* height: 120vh !important; */
}

@media only screen and (max-width: 769px) {
  .social_link_wrapper {
    justify-content: center !important;
  }

  .handleCenterForSm {
    display: flex;
    justify-content: center;
  }

  #certificatModal {
    width: 80% !important;
  }

  .d-itemVideo {
    display: inline-block !important;
    justify-content: end;
  }

  .buttonHomeExplore {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-parent {
    margin-top: 15px !important;
  }
}

/* --------------------------------- */
.snbjasasdjh {
  background: brown;
  margin-top: 8px;
  margin-bottom: -39px;
  width: 47px;
  z-index: 500;
  height: 47px;
  border-radius: 50%;
}

@media only screen and (min-width: 1380px) {
  .font-resize {
    font-size: 45px;
    line-height: 5rem;
  }

  .forLetterSpacing {
    letter-spacing: 6px !important;
  }

  .extraWidth {
    width: 140%;
  }
}

@media only screen and (min-width: 1136px) and (max-width: 1379px) {
  .font-resize {
    font-size: 35px;
    line-height: 4rem;
  }

  .forLetterSpacing {
    letter-spacing: 5px !important;
  }

  .extraWidth {
    width: 140%;
  }
}

@media only screen and (min-width: 1031px) and (max-width: 1135px) {
  .font-resize {
    font-size: 35px;
    line-height: 4rem;
  }

  .forLetterSpacing {
    letter-spacing: 5px !important;
  }

  .extraWidth {
    width: 130%;
  }
}

@media only screen and (max-width: 1030px) {
  .font-resize {
    font-size: 32px;
    line-height: 3.5rem;
  }

  .forLetterSpacing {
    letter-spacing: 4px !important;
  }

  .extraWidth {
    width: 120%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @media only screen and (max-width: 900px) and (min-width: 700px) {
    .line-one {
      width: 413px;
    }

    .forRespoMarginReduce {
      margin-left: 30px !important;
      margin-right: 50px !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .meal_details_type_title {
      margin-left: 0px !important;
      margin-bottom: -28px !important;
      font-size: 30px !important;
      text-align: center;
    }

    .handleVideoWidth {
      width: 350px;
      height: 350px;
    }

    .font-resizeSm {
      line-height: 80px !important;
      font-size: 45px;
    }

    .onHideLg {
      display: grid !important;
    }

    .onHideSm {
      display: none !important;
    }

    .headerButtonW {
      position: relative;
      left: 0px;
      margin-top: 10px !important;
    }

    .button-18 {
      margin: 0px !important;
      margin-bottom: 10px !important;
    }

    .handleAlignmentBanner {
      text-align: center;
    }

    .forRespoMarginReduce {
      margin-left: 30px !important;
      margin-right: 50px !important;
    }

    .paymentOptionsChoose {
      margin-top: 36px !important;
      margin-bottom: -25px !important;
      font-size: 18px;
    }

    .forRespoMarginReduce {
      margin-left: 30px !important;
      margin-right: 50px !important;
    }

    .carousel-slider {
      padding-top: 20px;
    }
  }

  @media only screen and (max-width: 650px) {
    .handleAboutFlex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      align-items: flex-start;
    }

    .extraWidth {
      width: 100%;
      padding-left: 15px;
      padding-right: 20px;
    }

    .font-resizeSm {
      line-height: 70px !important;
      font-size: 33px;
    }

    .extraCare {
      margin-top: 15px;
    }

    .forRespoMarginReduce {
      margin-left: 0px !important;
      margin-right: 30px !important;
    }

    /* .search-bar {
      width: 80% !important;
    } */
  }

  @media only screen and (max-width: 600px) {
    .mealnft.container {
      max-width: 400px;
    }

    .imgWalletBtn {
      width: 30px !important;
      margin: 0 auto;
      margin-top: -5px;
    }

    .btn-wallet-modal {
      font-size: 14px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .centralInMobile {
      text-align: center !important;
    }

    .mySwiper {
      width: 100%;
    }

    .sliderWidth {
      width: 400px;
    }

    .onHideLgSlider {
      display: none;
    }

    .onHideSmSlider {
      display: grid;
    }

    .glow_crypto_button {
      -webkit-animation: glowing 1500ms infinite;
      -moz-animation: glowing 1500ms infinite;
      -o-animation: glowing 1500ms infinite;
      animation: glowing 1500ms infinite;
    }

    @-webkit-keyframes glowing {
      0% {
        background-color: #10ac84;
        -webkit-box-shadow: 0 0 3px #10ac84;
      }

      50% {
        background-color: #28a745;
        -webkit-box-shadow: 0 0 20px #28a745;
      }

      100% {
        background-color: #10ac84;
        -webkit-box-shadow: 0 0 3px #10ac84;
      }
    }

    @-moz-keyframes glowing {
      0% {
        background-color: #10ac84;
        -moz-box-shadow: 0 0 3px #10ac84;
      }

      50% {
        background-color: #28a745;
        -moz-box-shadow: 0 0 20px #28a745;
      }

      100% {
        background-color: #10ac84;
        -moz-box-shadow: 0 0 3px #10ac84;
      }
    }

    @-o-keyframes glowing {
      0% {
        background-color: #10ac84;
        box-shadow: 0 0 3px #10ac84;
      }

      50% {
        background-color: #28a745;
        box-shadow: 0 0 20px #28a745;
      }

      100% {
        background-color: #10ac84;
        box-shadow: 0 0 3px #10ac84;
      }
    }

    @keyframes glowing {
      0% {
        background-color: #10ac84;
        box-shadow: 0 0 3px #10ac84;
      }

      50% {
        background-color: #28a745;
        box-shadow: 0 0 20px #28a745;
      }

      100% {
        background-color: #10ac84;
        box-shadow: 0 0 3px #10ac84;
      }
    }

    .glow_paynow_button {
      -webkit-animation: glowingpaynow 1500ms infinite;
      -moz-animation: glowingpaynow 1500ms infinite;
      -o-animation: glowingpaynow 1500ms infinite;
      animation: glowingpaynow 1500ms infinite;
    }

    @-webkit-keyframes glowingpaynow {
      0% {
        background-color: #2980b9;
        -webkit-box-shadow: 0 0 3px #2980b9;
      }

      50% {
        background-color: #007bff;
        -webkit-box-shadow: 0 0 20px #007bff;
      }

      100% {
        background-color: #2980b9;
        -webkit-box-shadow: 0 0 3px #2980b9;
      }
    }

    @-moz-keyframes glowingpaynow {
      0% {
        background-color: #2980b9;
        -moz-box-shadow: 0 0 3px #2980b9;
      }

      50% {
        background-color: #007bff;
        -moz-box-shadow: 0 0 20px #007bff;
      }

      100% {
        background-color: #2980b9;
        -moz-box-shadow: 0 0 3px #2980b9;
      }
    }

    @-o-keyframes glowingpaynow {
      0% {
        background-color: #2980b9;
        box-shadow: 0 0 3px #2980b9;
      }

      50% {
        background-color: #007bff;
        box-shadow: 0 0 20px #007bff;
      }

      100% {
        background-color: #2980b9;
        box-shadow: 0 0 3px #2980b9;
      }
    }

    @keyframes glowingpaynow {
      0% {
        background-color: #2980b9;
        box-shadow: 0 0 3px #2980b9;
      }

      50% {
        background-color: #007bff;
        box-shadow: 0 0 20px #007bff;
      }

      100% {
        background-color: #2980b9;
        box-shadow: 0 0 3px #2980b9;
      }
    }

    .grugHundRace {
      min-width: 0% !important;
      max-width: 85% !important;
    }

    .grugHundRaceText {
      min-width: 0% !important;
      max-width: 75% !important;
      padding-left: 0px !important;
    }

    .grugHundRacev2 {
      min-width: 0% !important;
      max-width: 75% !important;
    }

    .grugHundRacev2Text {
      min-width: 0% !important;
      max-width: 75% !important;
      padding-left: 0px;
    }
  }

  @media only screen and (max-width: 540px) {
    .meal_details_type_title {
      margin-left: 0px !important;
      margin-bottom: -28px !important;
      font-size: 21px !important;
      text-align: center;
    }

    .howItWorks_section-p {
      padding-top: 35px;
    }

    /* .search-bar {
      width: 80% !important;
    } */
  }

  @media only screen and (max-width: 500px) {
    .carousel-slider {
      padding-top: 0;
    }

    .font-resizeSm {
      line-height: 70px !important;
      font-size: 33px;
    }
  }

  @media only screen and (max-width: 480px) {
    .walletModal_icon {
      margin-left: 12.5% !important;
    }

    .font-resizeSm {
      line-height: 70px !important;
      font-size: 33px;
    }

    .modal-btn {
      width: 88px !important;
      font-size: 9px !important;
    }

    .howItWorks_section-p {
      padding-top: 36px;
    }

    .container-slider {
      height: 400px;
    }

    .carousel-slider {
      padding-top: 20px;
    }

    /* .search-bar {
      width: 100% !important;
    } */
  }

  @media only screen and (max-width: 425px) {
    .banner-text {
      margin-top: -8px;
    }

    .font-resizeSm {
      line-height: 50px !important;
      font-size: 37px;
    }

    .line-one {
      width: auto;
      letter-spacing: 5px !important;
    }

    .handleImgforRespons {
      margin-top: 20px !important;
      width: 70% !important;
    }

    .img-fluids {
      width: 20% !important;
    }

    .font-resize {
      line-height: 50px !important;
      font-size: 40px;
    }

    .font-resizeSm {
      line-height: 50px !important;
      font-size: 33px;
    }

    .carousel-slider {
      width: 90%;
      height: auto;
      margin: 0 auto;
    }

    .howItWorks_section-p {
      padding-top: 35px !important;
    }

    .carousel-slider {
      padding-top: 0;
    }

    /* .search-bar {
      width: 400% !important;
    } */
  }

  @media only screen and (max-width: 400px) {
    .meal_details_type_title {
      margin-left: 0px !important;
      margin-bottom: -28px !important;
      font-size: 16px !important;
    }

    .handleVideoWidth {
      width: 300px;
      height: 300px;
    }

    .font-resizeSm {
      line-height: 50px !important;
      font-size: 25px;
    }

    .container-slider {
      height: 300px;
    }

    .carousel-slider {
      padding-top: 10px;
    }

    .sliderWidth {
      width: 372px;
    }
  }

  @media only screen and (max-width: 376px) {
    .sliderWidth {
      width: 358px;
    }
  }

  @media only screen and (max-width: 360px) {
    .sliderWidth {
      width: 342px;
    }
  }

  @media only screen and (max-width: 350px) {
    .sliderWidth {
      width: 324px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .headerButtonW {
    position: relative;
    left: 15px;
  }
}

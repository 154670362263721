.handleTheLoginBody {
    background-color: #272d47;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    color: white;
}

.logindiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputBackground {
    background-color: #272D47;
    color: white;
    border: 1px solid rgb(88, 88, 88);
}

.handleLogInButton {
    background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%);
    border: none;
}

.forFalseHeader {
    width: 100%;
    height: 0px;
    background-color: #717db33f;
}

.handleLogInButton:hover {
    background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%) !important;
    border: none;
    cursor: pointer;
}

.handleLogoLogin {
    width: 80px;
    margin-top: -20px;
}

.forCard {
    background-color: #717db33f;
}

.forCard:hover {
    background-color: #717db33f;
    box-shadow: 0px 0px 41px 1px rgba(0, 0, 0, 0.73);
    -webkit-box-shadow: 0px 0px 41px 1px rgba(0, 0, 0, 0.73);
    -moz-box-shadow: 0px 0px 41px 1px rgba(0, 0, 0, 0.73);

}

.handleSendBtn {
    background-color: #6958be;
    border: none;
}

.handleSendBtn:hover {
    background-color: #6958be !important;
    border: none;
}



@media screen and (max-width:840px) {
    .handleTheLoginBody {
        background-color: #272d47;
        height: 100vh;
    }

    .forCard {
        background-color: #717db33f;
        margin-top: 20px;
        /* margin-bottom: 50px; */
        width: 100% !important;
    }
}
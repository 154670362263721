.rewardBg1{
    background-color: #006622;
    padding: 8px;
    color: white;
}
.rewardBg2{
    background-color: #662200;
    padding: 8px;
    color: white;

}
.rewardBg3{
    background-color: #006666;
    padding: 8px;
    color: white;

}
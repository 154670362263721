.section_wrapper {
  padding-top: 100px;
}

h1.welcome-text.text-race {
  font-size: 40px;
}

.handleAlignDogResult {
  padding-left: 15% !important;
}

.width-50 {
  width: 50%;
}

.claimdsl .button-metamask {
  max-width: auto;
}

.game_instructions_for_pc_mobile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4%;
}

.race-line {
  height: 100%;
  width: 6.5px;
  background: red;
  position: absolute;
  top: 0;
  z-index: 5;
}

.selected-dog {
  animation: flashing 2s linear infinite;
  animation-delay: 0.1s;
  -moz-animation: flashing 2s linear infinite;
  -webkit-animation: flashing 2s linear infinite;
  -o-animation: flashing 2s linear infinite;
  filter: drop-shadow(2px 2px 5px #bbff00);
  /* box-shadow: rgb(85, 91, 255) 0px 0px 0px 1px, rgb(31, 193, 27) 0px 0px 0px 2px, rgb(255, 217, 19) 0px 0px 0px 3px, rgb(255, 156, 85) 0px 0px 0px 4px, rgb(255, 85, 85) 0px 0px 0px 5px;  */
}

@keyframes flashing {
  0%,
  100% {
    -webkit-box-shadow: 0px 0px 6px 3px #f2e1f2;
    -moz-box-shadow: 0px 0px 6px 3px #f2e1f2;
    box-shadow: 0px 0px 6px 3px #f2e1f2;
    opacity: 1;
    transform: scale(1);
  }

  50% {
    -webkit-box-shadow: 0px 0px 6px 3px #daff33;
    -moz-box-shadow: 0px 0px 6px 3px #daff33;
    box-shadow: 0px 0px 6px 3px #daff33;
    opacity: 0.7;

    transform: scale(1.1);
  }

  25% {
    -webkit-box-shadow: 0px 0px 6px 3px #daa638;
    -moz-box-shadow: 0px 0px 6px 3px #daa638;
    box-shadow: 0px 0px 6px 3px #daa638;
    opacity: 0.4;
    transform: scale(1.05);
  }
}

.start-line {
  left: 50px;
}

.end-line {
  right: 50px;
}

.height-sm {
  min-height: auto !important;
}

.count-down {
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 40px;
  border-radius: 50%;
  background-color: lightgray;
  z-index: 9;
}

.count-downGo {
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 30px;
  border-radius: 50%;
  background-color: lightgray;
  z-index: 9;
}

.clock {
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 20px;
  background-color: #404549;
  border-radius: 5px;
  width: 45px;
  height: 35px;
  color: white;
  justify-content: center;
}

.clock::after {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  bottom: 50%;
  content: "";
  width: "100%";
  height: 2px;
  background-color: #232323;
  opacity: 0.4;
}

.time {
  font-size: 13px;
}

.race-redirect {
  animation: flux 2s linear infinite;
  animation-delay: 0.1s;
  -moz-animation: flux 2s linear infinite;
  -webkit-animation: flux 2s linear infinite;
  -o-animation: flux 2s linear infinite;
}

.race-redirect {
  font-family: neon;
  /* line-height: 6vw; */
  /* color: rgb(255, 255, 255) !important;  */
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 15px;
  margin-top: 25px;
}

@keyframes flux {
  0%,
  100% {
    text-shadow: 0 0 1vw #5a6372, 0 0 3vw #5c6575, 0 0 10vw #5c6575,
      0 0 10vw #5c6575;
    color: #ddddde;
  }

  50% {
    text-shadow: 0 0 0.5vw #5c6575, 0 0 1.5vw #44bd32, 0 0 5vw #44bd32,
      0 0 5vw #44bd32, 0 0 0.2vw #44bd32, 0vw 0vw 0.1vw #5a6372;
    color: #98eddc;
  }
}

#demo-simple-select-label {
  font-size: 14px;
}

/* .overflow-hidden{
    overflow:hidden !important;
} */
.container-fluid.overflow-hidden {
  padding-top: 0px;
}

.email-verify-btn {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  ) !important;
  color: white !important;
  border-radius: 0 4px 4px 0 !important;
  z-index: 9;
  /* right: 2.5px;
top: -2.3px;
padding: 16.5px 15px!important; */

  /* text-transform: none; */
}

.email-verify-btn02 {
  background-color: #5c5d60 !important;
  color: #d4d4d4 !important;
  border-radius: 0 4px 4px 0 !important;
  z-index: 9;
  /* right: 2.5px;
top: -2.3px; */
  /* padding: 16.5px 15px!important; */

  /* text-transform: none; */
}

.hideSm {
  display: inline-block;
}

.hideLg {
  display: none;
}

.dog-size {
  width: 40px;
}

.race_area {
  position: relative;
  background: rgb(75 75 78);
  padding: 0px 0 0 0;
  margin: 0 14px;
  width: 100% !important;
  max-height: 700px !important;
}

.race_width_2d3d {
  width: 70%;
}

.FullScreen {
  height: auto !important;
}

.full-one.fullscreen-enabled {
  display: flex;
  align-items: center;
}

.FullScreen:fullscreen {
  display: flex;
  align-items: center;
}

.FullScreen:fullscreen .single_item {
  max-height: 50px !important;
}

.full-one .single_item {
  max-height: 50px !important;
}

.fullscreen-enabled .fa-expand {
  display: none;
}

.FullScreen:fullscreen .fa-expand {
  display: none;
}

.FullScreen:fullscreen .unity {
  height: auto;
}

.single_item {
  position: relative;
  max-height: 17px !important;
  /* 26px */
  /* margin-bottom: 10px; */
  width: 100%;
  z-index: 6;
}

.fa-expand {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  z-index: 9;
  font-size: 35px;
  opacity: 0.6;
}

.fa-expand:hover {
  opacity: 1;
}

.track {
  border-top: 1px solid #fff;
}

.email-field button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.email-field {
  display: flex;
  align-items: center;
  position: relative;
}

.email-field input {
  background-color: transparent;
}

.email-field input:disabled {
  -webkit-text-fill-color: gray;
}

.email-field label {
  color: white;
}

.replays .unity {
  height: 70vh;
}

.result-btn {
  position: absolute !important;
  /* top: 160%; */
  padding: 0.375rem 0.75rem;
  /* transform: translateY(-158%); */
  top: 0.9rem;
  left: 0;
}

.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unity {
  height: 320px;
}

.fullscreen-enabled .unity {
  height: auto;
}

.start-position {
  text-align: left;
  margin-left: 1.5%;
}

.dog {
  width: 22px;
  /* width: fit-content; */
  max-height: 17px !important;
  /* 17 */
  object-fit: cover;
  margin-bottom: 13px;
}

@keyframes move {
  10%,
  100% {
    opacity: 1;
    transform: translate(96%, 0%);
  }

  0% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }
}

@-webkit-keyframes move {
  10%,
  100% {
    opacity: 1;
    transform: translate(96%, 0%);
    -webkit-opacity: 1;
    -webkit-transform: translate(96%, 0%);
  }

  0% {
    opacity: 1;
    -webkit-opacity: 1;
  }

  10% {
    opacity: 1;
    -webkit-opacity: 1;
  }
}

.img_dog {
  margin-left: 1.5%;
  text-align: left;
  width: 99.5%;
  -webkit-animation-name: move;
  animation-name: move;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);

  /* -webkit-animation: move 200s;
    -webkit-animation-play-state: paused; */
}

button.btn.btn-warning.btn-sm.text-white.me-4.start {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  padding: 7px 17px;
}

button.btn.btn-warning.btn-sm.text-white.me-4.pause {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  padding: 7px 17px;
}

button.btn.btn-warning.btn-sm.text-white.restart {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  padding: 7px 17px;
}

.handleforAlignInSm {
  text-align: center;
  margin-left: 15px !important;
}

.replay-container {
  height: 100%;
}

.race-selection-wrapper {
  display: flex;
  justify-content: space-around;
}

.your-footer-class .swal-footer {
  text-align: center !important;
}

@media screen and (max-width: 500px) {
  .handleBoxWidthForSm {
    /* min-width: 170px !important; */
    margin-top: 13px;
  }

  .replays .unity {
    height: 50vh;
  }

  .race-line {
    width: 2.9px;
  }

  .fa-expand {
    width: 30px;
  }

  .start-line {
    left: 21.5px;
  }

  .replay-container {
    height: 100%;
  }

  .end-line {
    right: 21.5px;
  }

  .text-sm {
    font-size: 15px;
  }

  .unity {
    height: 200px;
  }

  .img-standing {
    position: relative;
    bottom: -10px;
  }

  .race-redirect {
    font-size: 1.5rem;
  }

  .race-selection-wrapper {
    flex-wrap: wrap;
  }

  .hideSm {
    display: none;
  }

  /* .email-verify-btn{
        padding: 16px 5px!important;
right: 3px;
top: -2px;
        } */
  /* .email-verify-btn02{
        padding: 17px 15px!important;
right: 3px;
top: -2px;
        } */
  .height-sm {
    height: auto !important;
  }

  .hideLg {
    display: inline-block !important;
  }

  .handleAlignDogResult {
    padding-left: 15% !important;
  }

  .text-sm-centerR {
    text-align: center !important;
  }

  .handleforAlignInSm {
    text-align: center;
    margin-left: 15px !important;
  }

  .fa-expand {
    font-size: 20px;
  }

  .single_item .dog {
    width: 13px;
    margin-bottom: 18px;
    /* width: 7% !important; */
  }

  /* .email-wrapper{
        max-width: 270px;
    } */
  .email-verify-btn02 {
    font-size: 12px !important;
  }

  .count-down {
    width: 60px;
    height: 60px;
    font-size: 25px;
  }

  .count-downGo {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }

  @keyframes move {
    10%,
    100% {
      opacity: 1;
      transform: translate(92%, 0%);
    }

    0% {
      opacity: 1;
    }

    10% {
      opacity: 1;
    }
  }

  @-webkit-keyframes move {
    10%,
    100% {
      opacity: 1;
      transform: translate(92%, 0%);
      -webkit-opacity: 1;
      -webkit-transform: translate(92%, 0%);
    }

    0% {
      opacity: 1;
      -webkit-opacity: 1;
    }

    10% {
      opacity: 1;
      -webkit-opacity: 1;
    }
  }

  .race_area {
    background: rgb(75 75 78);
    padding: 0px 0 0 0;
    margin: 0 14px;
    max-height: 700px !important;
  }

  .single_item {
    position: relative;
    max-height: 13px !important;
    /* margin-bottom: 10px; */
    width: 100%;
  }

  .track {
    border-top: 1px solid #fff;
  }

  .img_dog {
    text-align: left;
    /* width: 100%; */
    width: 102.5%;
    animation-name: move;
    -webkit-animation-name: move;
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);

    /* -webkit-animation: move 200s;
        -webkit-animation-play-state: paused; */
  }

  .dog {
    width: 10px;
    /* width: fit-content; */
    max-height: 20px !important;
    object-fit: cover;
  }

  h3.videoTitle.mb-0.text-danger.ms-0.aos-init.aos-animate {
    font-size: 15px !important;
  }
}

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #5adaff 0,
    #5468ff 100%
  );
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

h3.videoTitle.mb-0.text-danger.ms-0.aos-init.aos-animate {
  font-size: 19px;
  font-weight: normal !important;
}

@media screen and (max-width: 768px) {
  .race_width_2d3d {
    width: 100%;
  }

  .width-50 {
    width: 100%;
  }

  .arrow {
    width: 50px;
  }

  .game_instructions_for_pc_mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media screen and (min-width: 501px) and (max-width: 766px) {
  /* .dog {
        width: 7% !important;
    } */
  .count-down {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }

  .count-downGo {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }

  .race-selection-wrapper {
    flex-wrap: wrap;
  }

  .race_area {
    background: rgb(75 75 78);
    padding: 0px 0 0 0;
    margin: 0 14px;
    max-height: 700px !important;
  }

  .single_item {
    position: relative;
    max-height: 12px !important;
    /* margin-bottom: 10px; */
    width: 100%;
  }

  .img_dog {
    text-align: left;
    /* width: 97%; */
    width: 95.5%;

    /* -webkit-animation: move 200s;
        -webkit-animation-play-state: paused; */
  }

  .dog {
    width: 18px;
    /* width: fit-content; */
    max-height: 20px !important;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .start-line {
    left: 30px;
  }

  .end-line {
    right: 30px;
  }

  /* .email-wrapper{
    max-width: 270px;
} */
}

@media screen and (min-width: 767px) and (max-width: 840px) {
  /* .dog {
        width: 7% !important;
    } */
  .count-down {
    width: 60px;
    height: 60px;
    font-size: 25px;
  }

  .count-downGo {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }

  .race-selection-wrapper {
    flex-wrap: wrap;
  }

  .race_area {
    background: rgb(75 75 78);
    padding: 0px 0 0 0;
    margin: 0 14px;
    max-height: 700px !important;
  }

  .single_item {
    position: relative;
    max-height: 10px !important;

    width: 100%;
  }

  .img_dog {
    text-align: left;
    /* width: 99%; */
    width: 97.5%;

    /* 
        -webkit-animation: move 200s;
        -webkit-animation-play-state: paused; */
  }

  .dog {
    width: 15px;
    /* width: 35px; */
    /* width: fit-content; */
    max-height: 20px !important;
    object-fit: cover;
    margin-bottom: 18px;
  }

  .start-line {
    left: 30px;
  }

  .end-line {
    right: 30px;
  }
}

@media screen and (min-width: 841px) and (max-width: 990px) {
  /* .dog {
        width: 6% !important;
    } */
  .count-down {
    width: 75px;
    height: 75px;
    font-size: 30px;
  }

  .count-downGo {
    width: 75px;
    height: 75px;
    font-size: 20px;
  }

  .race-selection-wrapper {
    flex-wrap: wrap;
  }

  .race_area {
    background: rgb(75 75 78);
    padding: 0px 0 0 0;
    margin: 0 14px;
    max-height: 700px !important;
  }

  .single_item {
    position: relative;
    max-height: 14px !important;
    /* max-height: 23px !important; */
    /* margin-bottom: 10px; */
    width: 100%;
  }

  .start-line {
    left: 28px;
  }

  .end-line {
    right: 28px;
  }

  .dog {
    width: 16px;
    /* width: 35px; */
    /* width: fit-content; */
    max-height: 25px !important;
    object-fit: cover;
    margin-bottom: 15px;
  }

  .img_dog {
    text-align: left;
    /* width: 97.5%; */
    width: 96%;

    /* -webkit-animation: move 200s; */
    /* -webkit-animation-play-state: paused; */
  }
}

@media screen and (min-width: 991px) and (max-width: 1400px) {
  /* .dog {
        width: 6% !important;
    } */
  .count-down {
    width: 100px;
    height: 100px;
    font-size: 35px;
  }

  .count-downGo {
    width: 100px;
    height: 100px;
    font-size: 25px;
  }

  .race_area {
    background: rgb(75 75 78);
    padding: 0px 0 0 0;
    margin: 0 14px;
    max-height: 700px !important;
  }

  .single_item {
    position: relative;
    max-height: 13px !important;
    /* max-height: 23px !important; */
    /* margin-bottom: 6px; */
    width: 100%;
  }

  .start-line {
    left: 44px;
  }

  .end-line {
    right: 44px;
  }

  .dog {
    width: 16px;
    /* width: 36px; */
    max-height: 23px !important;
    object-fit: cover;
    margin-bottom: 15px;
  }

  .img_dog {
    text-align: left;
    /* width: 100%; */
    width: 98.5%;

    /* -webkit-animation: move 200s; */
    /* -webkit-animation-play-state: paused; */
  }

  /* .email-verify-btn{
        padding: 15.7px 15px!important;
top: -1.7px;
        } */
  /* .email-verify-btn02{
        padding: 15.7px 15px!important;
top: -1.7px;
        } */
}

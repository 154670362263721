.handleAutoModalBody {
    position: relative;
    top: 48%;
    left: 50%;
    z-index: 90000;
    width: 400px;
    transform: translate(-50%, -50%);
    background-color: black;
    border: 2px solid white;
    box-shadow: 24px;
    color: white;
    border-radius: 5px;
    padding: 14px;
}

.emailVerifybtn {
    width: 20%;
}

.mobileVerifybtn {
    width: 28%;
}

@media screen and (max-width: 768px) {
    .mobileVerifybtn {
        width: 25%;
    }
}

@media screen and (max-width: 750px) {
    .handleResponsiveBtn{
        margin-left: 0px;
    }
}

@media screen and (max-width: 582px) {
    .mobileVerifybtn {
        width: 27%;
    }
}

@media screen and (max-width: 500px) {
    .mobileVerifybtn {
        width: 29%;
    }
}

@media screen and (max-width: 470px) {
    .mobileVerifybtn {
        width: 30%;
    }
}

@media screen and (max-width: 440px) {
    .emailVerifybtn {
    width: 25%;
}

.mobileVerifybtn {
    width: 40%;
}
}

@media screen and (max-width: 416px) {
    .mobileVerifybtn {
        width: 43%;
    }
}

@media screen and (max-width: 392px) {
    .mobileVerifybtn {
        width: 46%;
    }
}

@media screen and (max-width: 377px) {
    .emailVerifybtn {
        width: 28%;
    }
    
    .mobileVerifybtn {
        width: 57%;
    }
}

@media screen and (max-width: 350px) {
    .emailVerifybtn {
        width: 30%;
    }
    
    .mobileVerifybtn {
        width: 71%;
    }
}
.marginend {
  margin-right: 1rem;
}

.mainPartFooter {
  /* text-align: center; */
  /* margin-top: 50px; */
  margin-bottom: 30px;
}

.secondPartFooter {
  border-top: 1px solid rgb(151, 151, 151);
}

.handleSpace {
  margin-top: -1px;
  margin-bottom: 10px;
  padding-top: 100px;
  color: #0d6efd;
  font-weight: normal;
}

.handleSpace:hover {
  color: #134faa;
}

.made-love {
  /* color: rgb(203, 52, 52); */
  font-size: 18px;
  font-weight: normal;
  margin-top: 16px;
}

/* .made-love:hover{
    color: rgb(203, 52, 52);
} */

.footerBody {
  background-color: #130a1d;
}
@media screen and (max-width: 990px) {
  .mainPartFooter {
    /* text-align: center; */
    /* margin-top: 10px; */
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 440px) {
  .marginend {
    margin-right: 0.7rem;
  }

  .handleFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .handleForWidth {
    /* width: 250px; */
    margin-top: 10px;
    /* margin-bottom: 10px; */
    /* margin-left: 50px; */
  }

  .handleingCenterforSmall {
    text-align: center;
    margin-left: 50px;
  }
}

.dsl-catch {
    height: 100vh;
}

.dsl-catch .button-18 {
    border-radius: 8px !important;
}

.login-catchit {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-catchit i {
    color: white;
    background-color: red;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    line-height: 1;
    font-weight: 800;
    font-size: 17px;
    cursor: pointer;
    position: relative;
    top: -15px;
    left: -8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .5s all ease-in-out;
}

.login-catchit i:hover {
    color: red;
    background-color: white;
}

.spin-wheel .landing-button {
    position: relative;
}

.spin-wheel .login-catchit {
    top: 88% !important;
    left: 53% !important;
}

.spin-wheel .unity {
    height: 600px !important;
}

.spin-wheel .login-catchit button {
    font-size: 14px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

@media screen and (max-width:500px) {
    .dsl-catch {
        height: 80vh;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
    }

    .login-catchit {
        top: 55%;
    }

    .login-catchit button {
        font-size: 12px !important;
        min-height: 30px !important;
        background-color: #a36200 !important;
    }

    .login-catchit i {
        height: 20px;
        width: 20px;
        font-size: 13px;
    }

    .spin-wheel .unity {
        height: 235px !important;
    }

    .spin-wheel .login-catchit {
        top: 85% !important;
        left: 53% !important;
    }

    .spin-wheel .login-catchit button {
        font-size: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}
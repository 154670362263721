.imgAdmin {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.adminBody {
    height: auto;
}

.adminBodyRepo {
    height: 100vh;
}

.adminCard {
    background-color: #272D47;
    border-radius: 10px;
    /* width: 1600px !important; */
    /* border: 1px solid red */
}

/* .hovorIssue:hover{

} */
.adminBtn {
    background-color: #6958BE;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    /* margin-left: 0.6rem; */
    margin-top: 2rem;
}

.adminDataTable {
    border: 1px solid rgb(83, 83, 83);
    overflow: scroll;
}

.tableRow {
    padding: 2rem;
}

.AccessBtn {
    border: none;
    background-color: #42C48A;
    padding: 5px 5px;
    border-radius: 5px;
}

.deleteBtn {
    border-radius: 5px;
    background-color: #EF5858;
    padding: 5px 5px;
    border: none;
    margin-left: 5px;
}

.saveBtn {
    border-radius: 5px;
    background-color: #0d6efd;
    color: #e9e9e9;
    padding: 5px 8px;
    border: none;
    margin-right: 5px;
}

.editBtn {
    border-radius: 5px;
    background-color: #42C48A;
    padding: 5px 5px;
    border: none;
    /* margin-left: 3px; */
}

.mint_button {
    margin-top: 0;
    margin-right: 0;
}

.exclamatoryIcon {
    color: black;
    font-size: 3rem;
}

.delete {
    background-color: #272D47;
}

.deleteDiv p {
    color: white;
}

.Icon {
    text-align: center;
    font-size: 4.5rem;
    color: #EF5858;
}

.copyrightAdmin {
    background-color: #272D47;
    padding: 10px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.adminBtnDiv {
    /* margin-right: 3rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activeLegends {
    margin-bottom: -1.2rem;
}

/* .tableNormal{
    overflow: scroll;
} */

@media only screen and (max-width: 994px) {
    .tableNormal {
        overflow: scroll;
    }

    .adminCard {
        background-color: #272D47;
        border-radius: 10px;
        margin-right: 30px;
        /* width: 1600px !important; */
        /* border: 1px solid red */
    }
}

@media only screen and (max-width: 820px) {
    .editBtn {
        border-radius: 5px;
        background-color: #42C48A;
        padding: 5px 5px;
        border: none;
        /* margin-left: 6px; */
    }

    .tableNormal {
        overflow: scroll;
    }
}

@media only screen and (max-width: 600px) {
    .mint_button {
        margin-top: 42px;
        margin-left: -45px !important;
        margin-right: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .tableNormal {
        overflow: scroll;
    }

    .adminBody {
        height: auto;
    }

    .adminBodyRepo {
        height: 100vh;
    }

    .AccessBtn {
        border: none;
        background-color: #42C48A;
        padding: 5px 5px;
        border-radius: 5px;
        margin-left: 0rem;
    }

    .editBtn {
        border-radius: 5px;
        background-color: #42C48A;
        padding: 5px 5px;
        border: none;
        /* margin-left: px; */
    }

    .deleteBtn {
        border-radius: 5px;
        background-color: #EF5858;
        padding: 5px 5px;
        border: none;
        border-top: 2px;
    }

    .actionDiv {
        height: 4.1rem;
        /* margin-left: 1rem; */
    }

    .action {
        /* width: 9rem; */
    }

    .adminHidden {
        display: none;
    }

    .adminCard {
        background-color: #272D47;
        border-radius: 10px;
        margin-right: 30px;
        /* width: 1600px !important; */
        /* border: 1px solid red */
    }
}

@media only screen and (max-width: 350px) {
    /* .adminBtnDiv {
        margin-right: 1rem;
    } */

}
.isActive {
  font-weight: 600;
  border-bottom: 1px solid #000;
}


.logo-button {

  padding: 0;
  /* margin: 0 auto !important; */
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6d4f7;
  /* font-size: 20px; */
  /* letter-spacing: 2.5px; */
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  /* -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); */
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;

  -webkit-animation: glowing-logo 1500ms infinite;
  -moz-animation: glowing-logo 1500ms infinite;
  -o-animation: glowing-logo 1500ms infinite;
  animation: glowing-logo 1500ms infinite;
}

@keyframes glowing-logo {
  0% {
    background-color: #F32177;
    -webkit-box-shadow: 0 0 3px #1081e8;
  }

  50% {
    background-color: #F32177;
    -webkit-box-shadow: 0 0 20px #F32177;
  }

  100% {
    background-color: #F32177;
    -webkit-box-shadow: 0 0 3px #1081e8;
  }
}

.logo-button::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #9985ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.logo-button:hover::before {
  opacity: 0.2;
}

.logo-button span {
  position: absolute;
}

.logo-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(#1081e8), to(#F32177));
  background: linear-gradient(to left, #1081e8, #F32177);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.logo-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(#1081e8), to(#F32177));
  background: linear-gradient(to top, #1081e8, #F32177);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.logo-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(#1081e8), to(#F32177));
  background: linear-gradient(to right, #1081e8, #F32177);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.logo-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(#1081e8), to(#F32177));
  background: linear-gradient(to bottom, #1081e8, #F32177);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.dropdownFont {
  color: whitesmoke !important;
  font-family: Arial, Helvetica, sans-serif !important;
  width: 180px !important;
  position: relative;
  left: -25px;

}

.header-buttn {
  margin: 0px !important;
  max-height: 80px;
  display: flex;
  align-items: center;
}


#navbars {
  z-index: 200 !important;
  height: 80px !important;
  width: 100% !important;
  position: fixed !important;
  background: #121219 !important;
}

.navbarsContainer {
  background: #121219 !important;
}

.searchRadious {
  border-radius: 0px 6px 6px 0px;
}

.handleImage {
  width: 55%;
}

.NavbarToggle {
  background: rgb(194, 194, 194);
}

.listItem {
  font-size: 18px;
  margin-right: 20px;
}

.search-bar {
  width: 20% !important;
}



/* ------------------------------------- */
.button-18 {
  align-items: center;
  /* background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
  background: rgb(194, 116, 0);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0.8rem 0;


}

.button-18:hover,
.button-18:focus {
  background-color: #004c55;
  /* background-color: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
  color: #ffffff;
}

.button-18:active {
  background-color: #004c55;
  color: rgb(255, 255, 255, .7);
}

.button-18:disabled {
  cursor: not-allowed;
  background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%);
  color: rgba(0, 0, 0, .3);
}


/* ----------------------------------------- */
.rewordIcon{
  margin-right: 20px !important;
}

.menuText {
  font-size: 13.5px;
  font-weight: 400 !important;
  color: white !important;
  margin-left: 20px !important;
}

.menuTextButtonWallet {
  font-size: 13px;
  font-weight: 400 !important;
  color: white !important;
  margin-left: 0px !important;
}

div#responsive-navbar-nav {
  justify-content: flex-end;
}

.navbar-nav {
  display: flex;
  align-items: center !important;
}


#collasible-nav-dropdown {
  font-size: large;
  font-weight: 400 !important;
  color: white !important;
  margin-left: 5px;
}

.img-fluids {
  width: 8% !important;
  /* margin-left: -80px !important; */
}

button.mt-2.navbar-toggler.collapsed {
  color: rgb(207, 207, 207);
  background: #bebebe;
  border-color: rgb(255 255 255 / 10%);
}



.dropdown-menus {
  position: relative;
  display: inline-block;
  background: none;
  width: auto;
  display: flex;
  justify-content: center;
}

.menu-contents {
  display: none;
  position: absolute;
  color: white !important;
  background-color: #121219 !important;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 300;
  margin-top: 25px;
  border-radius: 5px 39px 5px 5px;
  padding: 10px;
}

.menu-btns {
  background: none;
  color: white;
  font-size: 16px;
  border: none;
}


.links {
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menus:hover .menu-contents {
  display: block;
}


/* -------------------------------------- */

@media only screen and (max-width: 1400px) {
  .menuText {
    font-size: 13.5px !important;
    font-weight: 400 !important;
    color: white !important;
    margin-left: 20px !important;
  }
  .rewordIcon{
    margin-right: 20px !important;
  }
  .menuTextButtonWallet {
    font-size: 13px;
    font-weight: 400 !important;
    color: white !important;
    margin-left: 0px !important;
  }

}

@media only screen and (max-width: 990px) {
  .search-bar {
    width: 40% !important;
  }

  .button-18 {
    margin-bottom: 20px;
  }

  .handleImage {
    width: 70%;
  }
}

@media only screen and (max-width: 900px) {
  .line-one {
    width: 413px;
  }

  .dropdownFont {
    position: relative;
    left: 0px !important;

  }

  .search-bar {
    width: 60% !important;
  }
}

@media only screen and (max-width: 901px) and (min-width: 991px) {
  .search-bar {
    width: 60% !important;
  }

  .dropdownFont {
    position: relative;
    left: 0px !important;

  }
  .rewordIcon{
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 769px) {
  /* .logo-button {
    font-size: small;
  } */

  .img-fluid {
    margin-left: 16px !important;
    width: 100%;
  }
  .rewordIcon{
    margin-right: 0px !important;
  }
  .background-nav {
    background: #000000;
    padding-bottom: 20px;
  }

  #navbar {
    background: #000000 !important
  }

  .centerForSm {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
  }

  .menuText {
    margin: 0px !important;
  }

  .menuTextButtonWallet {
    margin: 0px !important;
  }

  .menu-contents {
    border-radius: 5px 5px 5px 5px;
  }

  .dropdown-text {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .img-fluid {
    margin-left: -80px !important;
    width: 40%;
  }

  .marginReduceForWalletModal {
    margin: 10px !important;
    margin-bottom: 5px !important;
    margin-left: 0 !important;

  }

  .dropdownFont {
    margin: 5px 0 !important;
  }
  .rewordIcon{
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 440px) {
  .handleImage {
    width: 120% !important;
  }

  .button-18 {
    /* margin-top: 5px !important; */
    margin-bottom: 20px !important;
  }
  .rewordIcon{
    margin-right: 0px !important;
  }
}

@media (min-width: 576px) {
  .navStyle {
    padding: 30px 0px;
  }
  .rewordIcon{
    margin-right: 0px;
  }
}

@media screen and (max-width: 575px) {
  .slick-prev {
    left: -10px;
  }
  .rewordIcon{
    margin-right: 0px !important;
  }
  .dropdownFont {
    margin: 5px 0 1px 0 !important;
  }

  .slick-next {
    right: -10px;
  }
}

@media screen and (max-width: 600px) {
  .nav-link {
    padding: 0 !important;
  }
  .rewordIcon{
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
  .d-item1 {
    display: flex !important;
    justify-content: center;
  }

  .d-itemVideo {
    display: inline-block !important;
    justify-content: end;
  }

  .card {
    width: 75%;
  }

  .slick-prev {
    left: 1px;
  }
  .rewordIcon{
    margin-right: 0px !important;
  }
  .slick-next {
    right: -1px;
  }
}

@media only screen and (min-width: 1400px) {}
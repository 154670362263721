.handleTheProfileBody {
    /* background-color: #1A1A25; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 78vh;
}
.imageDivClass{
    display: flex;
    align-items: start;
    justify-content: start;
}
.gray {
    color: #6c757d;

}

.position-change {
    margin-right: -200px !important;
}

.profileTitles {
    font-family: Montserrat;
    font-size: 1.5rem;
    margin-top: 0rem;

}

.profileImgInput {
    background-color: transparent;
    width: 60%;
    margin: 20px auto;
    color: white;
}

.imgProfileDiv {
    margin-top: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profileForm {
    background-color: #060b1ab7;
    border-radius: 8px;

}

.profileTextBtn {
    display: none;
}

.desktopProfile {
    display: block;
}

.topmarginn {
    margin-top: 0;
}

.buttonsbelow_styles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0 !important;
}

.phone-btn-margin-left {
    margin-left: 11px !important;
}

.pc_btns {
    display: none;
}

.phn_btns {
    display: block;
}


@media only screen and (max-width: 720px) {
    .profileTextBtn {
        display: block;
    }

    .desktopProfile {
        display: none;
    }

    .topmarginn {
        margin-top: -72px;
    }

    .buttonsbelow_styles {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0 !important;
    }

    .phone-btn-margin-left {
        margin-left: 0 !important;
    }
}

@media only screen and (max-width: 580px) {
    .profileTitile {
        margin-left: 33px !important;
    }
    .imageDivClass{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .clickText {
        margin-right: 85px !important;
    }
    .mobilecenter{
        display: block;
        text-align: center;
    }
}

.profileUser {
    display: flex;
}

.profileUser input {
    width: 82%;
}

@media screen and (min-width: 768px) {
    .pc_btns {
        display: block;
    }

    .phn_btns {
        display: none;
    }
}

@media screen and (min-width: 880px) {
    .button-margin {
        margin-left: 12px !important;
    }
}

@media screen and (max-width: 880px) {
    .button-margin {
        margin-left: 12px !important;
    }
}

.profileUser button {
    border: none;
    border-radius: 0 5px 5px 0;
    background-color: #DC3545;
    color: white;

}

.profileBtn {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background-color: #DC3545;
    color: white;
    margin: 10px auto;
    margin-top: 1rem;
    width: 7rem;
    cursor: pointer;
    text-align: center;
}

.profileBtn.disabled {
    background-color: #da747e;
}

.profileInput {
    background-color: transparent;
    color: #a2a2a2;
    border: 1px solid #a2a2a24b;
}

.otpBtn {
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: #DC3545;
    color: white;
    margin: 10px auto;

}

.profileTitle {
    color: white;
    margin-top: 6.5rem;
    text-align: start;
}

.userDetails p {
    color: white;
    text-align: start;
    margin-bottom: 0rem;
    margin-top: 10px;
}

.userDetails input {
    width: 100%;
    padding: 6px;
    background-color: transparent;
    border: 1px solid #6a6d7481;
    border-radius: 6px;
    color: rgb(177, 167, 167);
}

.profileImg {
    width: 50%;
    background-color: transparent;
    color: white;
    margin: 1rem auto;
}

.errorMsg {
    margin: -0.4rem 0;
    font-weight: 500;
}

.btn-section {
    margin-top: -90px !important;
}

@media only screen and (max-width: 1080px) {
    .profileImgInput {
        width: 80%;

    }

    .profileUser input {
        width: 78.7%;
    }
}

@media only screen and (max-width: 990px) {
    .profileImgInput {
        width: 80%;

    }

    .profileUser input {
        width: 70.5%;
    }
}

@media only screen and (max-width: 768px) {
    .btn-section {
        margin-top: 0 !important;
    }
}

@media only screen and (max-width: 725px) {
    .profileImgInput {
        width: 80%;

    }
    .imageDivClass{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .handleTheProfileBody {
        /* background-color: #1A1A25; */
        margin-top: 50px;
        padding-top: 50px;
        height: auto;
    }

    .profileUser input {
        width: 81.2%;
    }

    .social-div {
        display: flex !important;
        justify-content: center !important;
    }
}

@media only screen and (max-width: 535px) {
    .profileImgInput {
        width: 80%;

    }
    .imageDivClass{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profileUser input {
        width: 74.40%;
    }
}

@media only screen and (max-width: 394px) {
    .profileImgInput {
        width: 80%;

    }
    .imageDivClass{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profileUser input {
        width: 71.5%;
    }
}

@media only screen and (max-width: 361px) {
    .profileImgInput {
        width: 80%;

    }
    .imageDivClass{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profileUser input {
        width: 70.5%;
    }
}
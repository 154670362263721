/* sanju code */

.main-thu_vs_var {
  height: 100vh;
}

.fontsizePrizing {
  font-size: 12px;
}

.sighupSmall {
  font-size: 12px;
}

.certificatModal .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white !important;
}

.certificatModal .swiper-pagination-bullet {
  height: 15px;
  width: 15px;
}

.certificatModal .swiper-button-next:after,
.certificatModal .swiper-rtl .swiper-button-prev:after,
.certificatModal .swiper-button-prev:after,
.certificatModal .swiper-rtl .swiper-button-next:after {
  color: white !important;
}

.colorfulText {
  background: linear-gradient(90deg,
      #ff0000,
      #ffff00,
      #ff00f3,
      #0033ff,
      #ff00c4,
      #ff0000);
  /* background-size: 400%; */
  margin-bottom: 36px;
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: animate 10s liner infinite;
}

.prize-color {
  background: linear-gradient(90deg, #f5030c 40%, #9501fb 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.prize-btn {
  background: #8522a5;
  border: none;
  color: white;
  /* padding left and right */
  padding: 5px 20px;
  height: 35px;
  border-radius: 1px;
}

.how-it-works .swiper-pagination {
  position: relative;
  margin-top: 15px;
}

.how-it-works .swiper-button-prev,
.how-it-works .swiper-button-next {
  display: none;
}

.font-size {
  font-size: 28px !important;
}

.thu_vs_var {
  margin-top: 0;
  margin-bottom: 0;
}

.section-padding-bottom {
  padding-bottom: 70px;
}

.ban_cont {
  justify-content: center;
  align-items: center;
}

.explore-btn {
  padding: 13px 40px !important;
}

.ban_txt {
  font-size: 24px;
}

.pc_device_banner {
  display: block;
}

.mobile_device_banner {
  display: none;
}

.buttonbelow {
  font-size: 13px;
  text-align: center;
  margin-top: -30px;
}

/* .block100vh {
    height: 100vh !important;
} */

/* sanju code */

.bannerImgDiv {
  position: absolute;
  top: 0%;
  height: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bannerImageStyle {
  width: 31%;
  cursor: pointer;
}

/* .bannerDiv {
    height: 480px;
} */

.bannerLgTextStyle {
  display: block;
  text-align: center;
  font-size: 24px;
}

.bannerSmTextStyle {
  display: none;
}

.hero-section {
  background-image: url("./../../../public/backbanner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: overlay !important;
  padding: 250px 0 180px 0;
}

.banner-text1 {
  position: relative;
  left: -35px;
  font-weight: bold;
  margin-bottom: 10px;
}

.banner-text2 {
  position: relative;
  right: -85px;
  margin-top: 10px;
  font-weight: bold;
}

.priceOffer {
  font-size: 16px !important;
}

.banner-button1 {
  margin-top: 100px;
}

.vs {
  width: 80%;
}

.banner-img1 {
  width: 85%;
}

.img2 {
  margin-left: auto;
}

.banner-part {
  padding: 20px 70px 20px 70px !important;
  /* padding: 70px !important; */
  margin-top: 75px;
}

.banner-wrapper {
  position: relative;
}

.banner-btn3 {
  height: 100px;
  width: 325px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
}

.banner-in-mobile {
  display: flex;
}

.banner-in-dekstop {
  display: none;
}


@media (max-width: 1440px) {
  .hero-section {
    padding: 150px 0 90px 0;
  }

  .banner-img1 {
    width: 80%;
  }

  .block100vh {
    height: auto !important;
  }

  .vs {}

  .banner-button1 {
    margin-top: 50px;
  }

  .banner-text1 {
    position: relative;
    left: -25px;
  }

  .banner-text2 {
    position: relative;
    left: 50px;
  }
}

@media (max-width: 1199px) {}

@media (max-width: 992px) {
  .hero-section {
    text-align: center;
  }

  .banner-img1 {
    width: 80%;
  }

  .vs {
    width: 50%;
  }

  .banner-text1 {
    left: 0px;
  }

  .banner-text2 {
    left: 0px;
    right: 0;
  }

  .banner-part .img-fluid {
    margin-left: 0px !important;
  }
}



@media (min-width: 651px) {
  .banner-in-dekstop {
    display: flex;
  }

  .banner-in-mobile {
    display: none;
  }
}

/* @media (min-width:0px) and (max-width: 800px) {
  .banner-in-mobile {
    display: flex;
  }
} */


@media (max-width: 767px) {
  .hero-section {
    padding: 100px 0 40px 0;
  }

  .hero-section {
    text-align: center;
  }

  .banner-img1 {
    width: 50%;
    margin: 0 auto;
  }

  .vs {
    width: 30% !important;
  }

  .banner-text1 {
    left: 0px;
    margin-top: 15px;
  }

  .banner-text2 {
    left: 0px;
    right: 0;
    margin-bottom: 15px;
  }

  .vs {
    text-align: center;
    margin: 0 auto;
  }

  .hero-section .img-fluid {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .banner-part {
    margin-top: 80px;
  }

  .banner-btn3 {
    height: 20px;
    width: 50px;
    bottom: 8px;
    /* bottom: 18px;
    height: 20px;
    width: 98px; */
  }
}

@media (max-width: 576px) {
  .hero-section .vs {
    width: 25% !important;
  }
}

@media (max-width: 480px) {}

@media (max-width: 361px) {}

@media only screen and (min-width: 1400px) {
  .main-thu_vs_var {
    height: 0;
  }

  .thu_vs_var {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1400px) {
  .explore-btn {
    padding: 10px 40px !important;
  }
}

@media only screen and (min-width: 1301px) {
  .bannerImageStyle {
    width: 21% !important;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1300px) {
  .bannerImageStyle {
    width: 31%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .nft_item_like {
    justify-content: center !important;
  }

  .nft-home-card {
    margin: 0px !important;
  }

  .pc_device_banner {
    display: none;
  }

  .mobile_device_banner {
    display: block;
  }

  /* .block100vh {
        height: 450px !important;
    } */
}

@media only screen and (max-width: 520px) {
  .nfts-landing-margin {
    margin-top: -50px !important;
  }

  .certificatModal .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }

  .priceOffer {
    font-size: 15px !important;
    display: "block"
  }

  .play-btn-margin {
    margin-top: 1.5rem !important;
    margin-bottom: 2rem !important;
  }

  .landing-padding {
    padding-top: 20px !important;
  }

  .close-date {
    font-size: 22px !important;
  }

  .bannerLgTextStyle {
    display: none;
  }

  .prize-color {
    font-size: 40px !important;
  }

  .buy-nft-home {
    font-size: 20px !important;
  }

  .prize-name {
    font-size: 16px !important;
  }

  .prize-btn {
    font-size: 16px !important;
    /* height: unset !important; */
  }

  .how-it-works .swiper-button-prev,
  .how-it-works .swiper-button-next {
    display: block;
    top: 100%;
  }

  .how-it-works .swiper-button-next:after,
  .how-it-works .swiper-button-prev:after {
    font-size: 23px;
  }

  .how-it-works .swiper-pagination {
    position: absolute;
    margin-top: 0px;
  }

  .how-it-works .swiper-button-next {
    left: 60%;
    transform: translateX(-50%);
  }

  .how-it-works .swiper-button-prev {
    left: 40%;
    transform: translateX(-50%);
  }

  .bannerImgDiv {
    position: absolute;
    top: 5%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .font-size {
    font-size: 22px !important;
  }

  .priceOffer {
    font-size: 14px !important;
    display: block;
  }

  .banner-part {
    padding: 20px 10px 20px 10px !important;
    /* padding: 70px !important; */
  }

  .bannerImageStyle {
    width: 55%;
    margin-bottom: 50px;
    cursor: pointer;
  }

  /* .bannerDiv {
        height: 450px;
    } */

  .bannerSmTextStyle {
    margin-top: -27px;
    display: block;
    text-align: center;
    font-size: 17px;
  }

  .explore-btn {
    padding: 8px 30px !important;
  }

  /* .banner-wrapper img {
    height: 250px;
  } */
}


@media only screen and (min-width: 366px) and (max-width: 380px) {
  .prize-name {
    font-size: 15px !important;
  }

  .priceOffer {
    font-size: 13px !important;
    display: "block"
  }

  .prize-btn {
    font-size: 12px !important;
    /* height: unset !important; */
  }

  .font-size {
    font-size: 18px !important;
  }

  .bannerImgDiv {
    position: absolute;
    top: -27px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.button-metamask {
  align-items: center;
  background: rgb(194, 116, 0);
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 12px !important;
  font-weight: 350;
  justify-content: center;
  line-height: 20px;
  max-width: 400px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0;
  /* animation: flash 1s infinite; */

  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@media only screen and (max-width: 365px) {
  .prize-btn {
    font-size: 12px !important;
    /* height: unset !important; */
  }

  .prize-name {
    font-size: 14px !important;
  }

  .priceOffer {
    font-size: 13px !important;
    display: "block"
  }
}

/* @keyframes flash {
    0% {
      background-color: blue;
    }
    50% {
      background-color: yellow;
    }
    100% {
      background-color: blue;
    }
  } */

@keyframes glowing {
  0% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }

  50% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 20px rgb(194, 116, 0);
  }

  100% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }
}

.button-metamask:hover,
.button-metamask:focus {
  background-color: rgb(163, 98, 0);
  color: #ffffff;
}

.button-metamask:active {
  background-color: rgb(163, 98, 0);
  color: rgb(255, 255, 255, 0.7);
}



/*
@media only screen and (min-width:385px) and  (max-width: 500px) {
    .bannerLgTextStyle {
        display: none;
    }

    .bannerImgDiv {
        margin-top: 45px;
        height:400px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .bannerImageStyle {
        width: 55%;
        margin-bottom: 50px;
    }

    .bannerDiv {
        height: 450px;
    }

    .bannerSmTextStyle {
        margin-top: -27px;
        display: block;
        font-size: 14px;
    }
} */
.payment-wrapper {
    background-color: #272d47;
    border-radius: 12px;
    padding: 30px 25px;
    word-wrap: break-word;
}

.flx {
    word-wrap: break-word;
    flex-wrap: wrap;
}

.bsc-input {
    width: 100%;
}

.bsc-input label {
    color: white !important;
}

#outlined-basic {
    color: white;
    border-color: white;
}

.bsc-input .MuiTextField-root {
    width: 100%;
}

.bsc-input .MuiInputBase-root {
    width: 100%;
    border-color: white;
}

.bsc-input .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.bsc-input .MuiOutlinedInput-notchedOutline:hover {
    border-color: white;
}

.bsc-input .MuiInputBase-root::before {
    border-color: white !important;
}

@media screen and (max-width: 992px) {
    .payment-wrapper {
        margin-right: 25px;
    }
}

@media screen and (max-width: 576px) {
    .payment-wrapper {
        padding: 30px 15px;
    }

    .payment-wrapper h5 {
        word-break: break-word;
    }
}

@media screen and (max-width: 440px) {
    .pay .button-18 {
        /* margin-top: 5px !important; */
        margin-bottom: 0px !important;
    }
}
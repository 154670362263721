.dashboardDiv {
    background-color: #1A1C33;
    height: 100%;
    width: 100%;
}

.marginAlign {
    margin-left: -20px;
}

.handleHeightDMenu {
    height: 100vh;
}

.dashboardTxt {
    color: white;
    line-height: 10px;

}

.dashboardTxt p {
    font-size: 0.9rem;
}

.card-row {
    padding-bottom: 12.2rem;
}

.cardDash {
    background-color: #272D47;
    cursor: pointer;
}

svg {
    color: white;
}

.demoP {
    visibility: hidden;
}

.coinsIcon {
    font-size: 3rem;
    margin-right: 0rem;
    margin-bottom: -0.1rem;
}

.parcentage_text {
    line-height: 12px;
}

.dashboardTxt h6 {
    font-size: 0.9rem;
    text-align: start;
    width: 100%;
}

@media screen and (max-width: 350px) {
    .handleHeightDMenu {
        height: 100vh !important;
    }

}

@media screen and (min-width: 351px) and (max-width: 500px) {
    .handleHeightDMenu {
        height: 100vh !important;
    }

    .dashboardTitle {
        padding-left: 48px;
    }
}

@media only screen and (max-width: 994px) {
    .dashboardDiv {
        background-color: #1A1C33;
        height: auto;
        background-size: cover;
    }


}